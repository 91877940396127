import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class VtoService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }
}

export default {
    vtoService: 'VTO_SERVICE',
    configStore: 'CONFIG_STORE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    vtoViewController: 'VTO_VIEW_CONTROLLER',
    vtoCTAButtonViewController: 'VTO_CTA_BUTTON_VIEW_CONTROLLER',
    vtoSkinCareViewController: 'VTO_SKIN_CARE_CONTROLLER',
    httpTransport: 'HTTP_TRANSPORT',
    quizApiSdk: 'QUIZ_API_SDK',
    personalizationApiSdk: 'PERSONALIZATION_API_SDK',
    quizApiRepository: 'QUIZ_API_REPOSITORY',
    personalizationRepository: 'PERSONALIZATION_REPOSITORY',
    userInfoRepository: 'USER_INFO_REPOSITORY',
    surveyRepository: 'SURVEY_REPOSITORY',
    surveyApiSdk: 'SURVEY_API_SDK'
};
