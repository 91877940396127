import { convertToExportedView } from '@estee/elc-service';
import { VTO_CTA_BUTTON } from '@estee/elc-service-view-names';
import { IVtoCTAButton } from './VtoCTAButton';
import serviceNames from '~setup/VtoService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVtoCTAButton>(
    () => import('./VtoCTAButton'),
    VTO_CTA_BUTTON,
    serviceNames.vtoCTAButtonViewController
);
