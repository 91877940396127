import { convertToExportedView } from '@estee/elc-service';
import { VTO_SKIN_CARE } from '@estee/elc-service-view-names';
import { IVtoSkinCare } from './VtoSkinCare';
import serviceNames from '~setup/VtoService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVtoSkinCare>(
    () => import('./VtoSkinCare'),
    VTO_SKIN_CARE,
    serviceNames.vtoSkinCareViewController
);
