import { convertToExportedView } from '@estee/elc-service';
import { VTO } from '@estee/elc-service-view-names';
import { IVto } from './Vto';
import serviceNames from '~setup/VtoService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVto>(
    () => import('./Vto'),
    VTO,
    serviceNames.vtoViewController
);
