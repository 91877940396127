import { getModuleFederatedName, setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import { VTO, VTO_CTA_BUTTON, VTO_SKIN_CARE } from '@estee/elc-service-view-names';
import serviceNames, { VtoService } from '~setup/VtoService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

import './exported/vto/index';
import './exported/vto-cta-button/index';
import './exported/vto-skin-care/index';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

VtoService.setViews(name, getModuleFederatedName(name), version, [
    VTO,
    VTO_CTA_BUTTON,
    VTO_SKIN_CARE
]);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.vtoService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBus: {
        serviceBusBaseClass
    }
});
